.pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.pagination button {
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    margin: 0 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: transparent;
    color: #000000;
    border-color: #000000;
}

.pagination button.active {
    font-weight: 900;
    border-color: #101010;
    background: #0275d8;
    color: #101010;
}