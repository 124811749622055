.level-1 {
    background-color: #F07F4E !important;
    border: 0px !important;
}

.level-2 {
    background-color: #008DCA !important;
    border: 0px !important;
}

.level-3 {
    background-color: #00A554 !important;
    border: 0px !important;
}

.level-4 {
    background-color: #7366A3 !important;
    border: 0px !important;
}

.level-5 {
    background-color: #D10007 !important;
    border: 0px !important;
}

.level-6 {
    background-color: #CF7AB2 !important;
    border: 0px !important;
}

.level-text-1 {
    color: #F07F4E !important;
}

.level-text-2 {
    color: #008DCA !important;
}

.level-text-3 {
    color: #00A554 !important;
}

.level-text-4 {
    color: #7366A3 !important;
}

.level-text-5 {
    color: #D10007 !important;
}

.level-text-6 {
    color: #CF7AB2 !important;
}

.list-items-1:hover {
    color: #fff !important;
    background-color: #f07f4e54 !important;
}

.list-items-2:hover {
    color: #fff !important;
    background-color: #008DCA54 !important;
}

.list-items-3:hover {
    color: #fff !important;
    background-color: #00A55454 !important;
}

.list-items-4:hover {
    color: #fff !important;
    background-color: #7366A354 !important;
}

.list-items-5:hover {
    color: #fff !important;
    background-color: #D1000754 !important;
}

.list-items-6:hover {
    color: #fff !important;
    background-color: #CF7AB254 !important;
}